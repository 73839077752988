import React from 'react';
import { PageType, pageTypeToCrudComponentMapper } from '@biotmed/data-components';
import { FullDetailsTab, FullDetailsTabTypeEnum } from '@biotmed/settings-sdk';
import { toSnakeCase } from '@biotmed/base-components';
import { MainViewIFrame } from 'src/components/MainViewIFrame';

export const getDynamicTabs = (tabs: FullDetailsTab[]) => {
  return (
    tabs?.map(tab => ({
      label: (tab.type === FullDetailsTabTypeEnum.Template ? tab.template?.title : tab.externalPage?.title) ?? '',
      tabKey: getTabKey(tab) ?? '',
    })) ?? []
  );
};

const getTabKey = (tab: FullDetailsTab) => {
  if (tab.type === FullDetailsTabTypeEnum.Template) {
    return `${tab.template?.entityTypeName}-${tab.template?.name}`;
  }

  if (tab.type === FullDetailsTabTypeEnum.ExternalPage) {
    return toSnakeCase(tab.externalPage?.title);
  }

  return '';
};

export const getDynamicRoutes = (tabs: FullDetailsTab[]) => {
  return (
    tabs?.map(tab => {
      let Component: React.FC<any> | undefined;
      let props: Record<string, any>;

      if (tab.type === FullDetailsTabTypeEnum.Template) {
        Component = pageTypeToCrudComponentMapper.get(tab.template?.entityTypeName as PageType);
        props = {
          templateProps: { templateId: tab.template?.id },
          mainTitle: tab.template?.title,
          // required in order to differentiate between crud components of the same entity type
          // without this key the filter, search and order are not reset when switching between
          // tabs of the same entity type
          key: getTabKey(tab),
        };
      } else if (tab.type === FullDetailsTabTypeEnum.ExternalPage) {
        Component = MainViewIFrame;
        props = { url: tab.externalPage?.url, cookies: tab.externalPage?.cookies };
      }

      return {
        path: `/${getTabKey(tab)}`,
        render: (additionalProps: any) =>
          Component ? <Component {...(props ?? {})} {...(additionalProps ?? {})} /> : null,
      };
    }) ?? []
  );
};
