import { getSdkApi } from '@biotmed/sdk-api-provider';
import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { actions as loginActions } from 'src/redux/data/login';
import { UserResponse as OrganizationUserResponse } from '@biotmed/organization-sdk';
import { UserResponse } from '@biotmed/ums-sdk';
import { actions } from '..';

function* getOrganizationSelfUser(action: ReturnType<typeof loginActions.loginSuccess>): any {
  try {
    const response: AxiosResponse<OrganizationUserResponse> = yield call(getSdkApi().organization.userApi.getSelf);
    yield put(actions.onOrganizationUserDetailsLoaded(response.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getOrganizationUserSelf: ', e);
  }
}

function* getSelfUser(action: ReturnType<typeof loginActions.loginSuccess>): any {
  try {
    const response: AxiosResponse<UserResponse> = yield call(getSdkApi().ums.selfUserApi.selfGetUser);
    yield put(actions.onUserDetailsLoaded(response.data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getUserSelf: ', e);
  }
}

export default function* watch() {
  yield all([
    takeLatest(loginActions.loginSuccess, getOrganizationSelfUser),
    takeLatest(loginActions.loginSuccess, getSelfUser),
  ]);
}
