import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { activateUserUrl } from '@biotmed/auth-pages';
import { SessionExpiredModal, Theme, useIdleTimer } from '@biotmed/base-components';
import {
  attachCrudComponentRenderer,
  dataComponentsConfig,
  EntityTypeEnum,
  PageTypeEnum,
  pageTypeToActionsMapper,
  pageTypeToCrudActionsToggleMapper,
  expandCrudComponentRenderer,
  expandCrudSliceMapper,
  PortalTypeEnum,
  CrudActionsEnum,
  EntityType,
  PageType,
  DataComponentsParamsProvider,
} from '@biotmed/data-components';

import LanguageProvider from 'src/components/LocaleProvider';
import AppConfig from 'src/config/AppConfig';
import { getThemeOverrideIfExists } from 'src/themes/theme';

import { AppBrandingHandler } from 'src/branding/AppBrandingHandler';
import { utilsConfig } from '@biotmed/utils';
import GlobalStyle from '../../global.styled';
import { actions as loginActions } from '../../redux/data/login';
import { selectors } from '../../redux/data/login/modules/slice';
import { selectors as userSelectors } from '../../redux/data/user/modules/slice';
import AppComponent from '../Navigation';
import RoutesEnum from '../Navigation/modules/routes';

interface AppProps {}

const promptTimeoutInMs = 30000;

const baseURL = window.location.origin;

const emailConfirmationLandingPage = () => `${baseURL}/${RoutesEnum.AUTH}/${activateUserUrl}`;

const hiddenInputFieldsMapper = (entityType: EntityType | undefined) => {
  if (
    [
      EntityTypeEnum.PATIENT,
      EntityTypeEnum.CAREGIVER,
      EntityTypeEnum.ORGANIZATION_USER,
      EntityTypeEnum.DEVICE,
      EntityTypeEnum.USAGE_SESSION,
      EntityTypeEnum.GENERIC_ENTITY,
      EntityTypeEnum.REGISTRATION_CODE,
    ].includes(entityType as EntityTypeEnum)
  ) {
    return ['_ownerOrganization'];
  }
  return [];
};

const App: React.FC<AppProps> = () => {
  // Create redux store with session state
  const dispatch = useDispatch();
  const isLoggedIn: boolean = useSelector(selectors.getIsLoggedIn);
  const userData = useSelector(userSelectors.getOrganizationUserDetails);

  const [idleTimerManager] = useIdleTimer({
    isLoggedIn,
    timeout: AppConfig.IDLE_TIMEOUT_MS,
    promptTimeout: promptTimeoutInMs, // The time when the user becomes idle until the onIdle function is called
    onIdle: () => dispatch(loginActions.logout()),
  });

  useEffect(() => {
    dataComponentsConfig.init({
      applicationType: PortalTypeEnum.ORGANIZATION_PORTAL,
      emailConfirmationLandingPage,
      expandCrudComponentRenderer,
      expandCrudSliceMapper,
      attachCrudComponentRenderer,
      hiddenInputFieldsMapper,
      actionsMapper: pageTypeToActionsMapper,
      getEnvironment: () => AppConfig.ENVIRONMENT,
      crudActionsToggleMapper: (pageType?: PageType) => {
        const mapper = pageTypeToCrudActionsToggleMapper(pageType);

        if (pageType === PageTypeEnum.DEVICE) {
          return { ...mapper, [CrudActionsEnum.ADD]: false };
        }
        if (pageType === PageTypeEnum.USAGE_SESSION) {
          return { ...mapper, [CrudActionsEnum.ATTACH]: false, [CrudActionsEnum.DETACH]: false };
        }
        if (pageType === PageTypeEnum.ORGANIZATION) {
          return {
            ...mapper,
            [CrudActionsEnum.ADD]: false,
            [CrudActionsEnum.ATTACH]: false,
            [CrudActionsEnum.DETACH]: false,
            [CrudActionsEnum.EDIT]: false,
            [CrudActionsEnum.DELETE]: false,
          };
        }
        return mapper;
      },
      idleTimerManager: {
        pause: idleTimerManager?.pause,
        activate: idleTimerManager?.activate,
      },
      cookiesExpirationInMinutes: AppConfig.COOKIES_EXPIRATION_IN_MINUTES,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    utilsConfig.init({
      codeSnippetIframeUrl: AppConfig.CODE_SNIPPET_IFRAME_URL,
      codeSnippetTimoutInMS: AppConfig.CODE_SNIPPET_TIMEOUT_IN_MS,
      codeSnippetIframeCreationMaxRetries: AppConfig.CODE_SNIPPET_IFRAME_CREATION_MAX_RETRIES,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      idleTimerManager?.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    async function initTheme() {
      const updatedTheme = await getThemeOverrideIfExists();
      setTheme(updatedTheme);
    }

    initTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      {theme && (
        <ThemeProvider theme={theme}>
          <DataComponentsParamsProvider currentLoggedInUserData={userData}>
            <LanguageProvider>
              <>
                <AppBrandingHandler />
                <GlobalStyle />
                <SessionExpiredModal
                  open={!!idleTimerManager?.isPrompted}
                  onContinue={() => idleTimerManager?.activate()}
                  onLogOff={() => {
                    dispatch(loginActions.logout());
                    idleTimerManager?.setIsPrompted(false);
                  }}
                  countdownTimeout={promptTimeoutInMs}
                />
                <AppComponent />
              </>
            </LanguageProvider>
          </DataComponentsParamsProvider>
        </ThemeProvider>
      )}
    </BrowserRouter>
  );
};

export default App;
